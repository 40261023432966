import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import BookImage from '../../components/book/BookImage';
import Seo from '../../components/seo';
import PageHeader from '../../components/PageHeader';

function ReleasesPage({ data }) {
  const title = 'Udgivelser';

  return (
    <Layout>
      <Seo title={title} description="" />
      <div className="columns is-centered">
        <div className="column is-9">
          <PageHeader
            title={title}
            subtitle="Her ser du mine bøger. De bliver udgivet på Forlaget Frydenlund.
            Klik på bøgerne, hvis du vil vide mere."
          />

          <hr className="is-hidden-mobile" />
        </div>
      </div>
      <div className="columns is-centered">
        <div className="column is-3">
          <BookImage
            link="/udgivelser/naar-det-aldrig-er-godt-nok/"
            image={data.book1.childImageSharp.fluid}
            alt="Når det aldrig er godt nok - Børn og unge med perfektionisme"
          />
        </div>
        <div className="column is-3">
          <BookImage
            link="/udgivelser/alt-for-bange-for-sygdom/"
            image={data.book2.childImageSharp.fluid}
            alt="Alt for bange for sygdom - Helbredsangst hos børn og unge"
          />
        </div>
        <div className="column is-3">
          <BookImage
            link="/udgivelser/hunde-kryb-og-smaa-lukkede-rum/"
            image={data.book3.childImageSharp.fluid}
            alt="Hunde, kryb og små lukkede rum - Fobier hos børn og unge"
          />
        </div>
      </div>

      <div className="columns is-centered">
        <div className="column is-3">
          <BookImage
            link="/udgivelser/hvad-taenker-de-andre/"
            image={data.book4.childImageSharp.fluid}
            alt="Hvad tænker de andre? - Socialfobi hos børn og unge"
          />
        </div>
        <div className="column is-3">
          <BookImage
            link="/udgivelser/hvorfor-kan-jeg-ikke-bare-lade-vaere/"
            image={data.book5.childImageSharp.fluid}
            alt="Hvorfor kan jeg ikke bare lade være? - OCD-guide til børn, teenagere og deres forældre"
          />
        </div>
        <div className="column is-3">
          <BookImage
            link="/udgivelser/slip-mig-fri/"
            image={data.book6.childImageSharp.fluid}
            alt="Slip mig fri! - Guide til børn og unge med separationsangst"
          />
        </div>
      </div>
    </Layout>
  );
}

export default ReleasesPage;

export const image = graphql`
  fragment image on File {
    childImageSharp {
      fluid(maxWidth: 500) {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  }
`;

export const query = graphql`
  query {
    book1: file(
      relativePath: { eq: "books/naar-det-aldrig-er-godt-nok.jpeg" }
    ) {
      ...image
    }
    book2: file(relativePath: { eq: "books/alt-for-bange-for-sygdom.jpeg" }) {
      ...image
    }
    book3: file(
      relativePath: { eq: "books/hunde-kryb-og-smaa-lukkede-rum.jpeg" }
    ) {
      ...image
    }
    book4: file(relativePath: { eq: "books/hvad-taenker-de-andre.jpeg" }) {
      ...image
    }
    book5: file(
      relativePath: { eq: "books/hvorfor-kan-jeg-ikke-bare-lade-vaere.jpeg" }
    ) {
      ...image
    }
    book6: file(relativePath: { eq: "books/slip-mig-fri.jpeg" }) {
      ...image
    }
  }
`;

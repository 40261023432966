import React from 'react';
import Img from 'gatsby-image';
import { Link } from 'gatsby';

function BookImage({ image, link, alt }) {
  return (
    <Link to={link}>
      <Img className="full-width-image book-image" alt={alt} fluid={image} />
    </Link>
  );
}

export default BookImage;
